import { throwError } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import _ from 'lodash';
import { AxiosInstance, AxiosStatic } from 'axios';
import { TemporaryFixService } from '@clodeo/libs/core/common/temporary-fix.service';
import { TokenRestService } from '@clodeo/libs/core/rest/account/token/token-rest.service';
import { environment } from '../../../environments/environment';
const temporaryService: TemporaryFixService = new TemporaryFixService();
const tokenRestService: TokenRestService = new TokenRestService(
  `${environment.ENDPOINTS.API_ACCOUNT}`
);

export class MUser {
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
  user: {
    id: string;
    fullName: string;
    email: string;
    isVerified: boolean;
    hasCompany: boolean;
    isOwner: boolean;
    ownerId: string;
    companyId: string;
    isShowOnlyBranch: boolean;
    isShowOnlyDepartement: boolean;
    roles: string[];
  };
  access_token: string;
}

let data: MUser;

// just for experimental, so this function need to improve
const refreshToken = () => {
  const payload = {
    grant_type: 'refresh_token',
    refresh_token: data.refreshToken,
    client_secret: 'VpFraMKSgptTeZUz',
    // client_id: 'koperasi-sicepat-admin-web'
  };
  return tokenRestService.getToken(payload).pipe(
    tap((loginData: never) => {
      data = loginData;
      localStorage.setItem('currentUser', JSON.stringify(loginData));
      window.location.reload();
    })
  );
};

export class AuthenticationService {
  clientId = 'koperasi-sicepat-admin-web';
  tokenRestService = new TokenRestService(
    `${environment.ENDPOINTS.API_ACCOUNT}`
  );
  userRestService: TokenRestService;
  constructor() {
    this.userRestService = new TokenRestService(
      `${environment.ENDPOINTS.API_ACCOUNT}`,
      this.axiosInterceptors
    );
    const currentUser = localStorage.getItem('currentUser');

    // checking if user manually insert the localstorage
    let user;
    try {
      user = JSON.parse(currentUser);
    } catch (e) {
      this.logout();
    }

    if (user && _.has(user, 'access_token')) {
      this.user = user as any;
    } else {
      this.user = null;
    }
  }

  set user(newData: MUser) {
    data = newData;
  }

  get user() {
    return data;
  }

  axiosInterceptors(
    axios: AxiosInstance | AxiosStatic,
    interceptRequest = true,
    interceptResponse = true
  ) {
    axios.interceptors.request.use((request) => {
      request.headers.common['Timezone-Offset'] =
        _.get(data, 'timezone_offset') || new Date().getTimezoneOffset();
      request.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
      request.headers.common['Accept'] = 'application/json, text/plain, */*';
      // if use multi language this config need to improve
      request.headers.common['Accept-Language'] = 'id-ID';

      return request;
    });

    if (interceptRequest) {
      axios.interceptors.request.use((request) => {
        if (data) {
          request.headers.common[
            'Authorization'
          ] = `Bearer ${data.access_token}`;
        }
        return request;
      });
    }

    if (interceptResponse) {
      axios.interceptors.response.use(
        (response) => {
          temporaryService.adressObjectNullHandler(response);

          return response;
        },
        async (error) => {
          const response = error.response;
          const errorCode = _.get(response, 'data.code');

          if (
            response &&
            errorCode === 401 &&
            response.config &&
            response.config.headers &&
            response.config.headers['Authorization']
          ) {
            return refreshToken()
              .pipe(
                switchMap(function (accessToken) {
                  data = accessToken as any;
                  response.config.headers[
                    'Authorization'
                  ] = `Bearer ${accessToken}`;
                  return axios.request(response.config);
                }),
                catchError((err) => {
                  // notifications.show({
                  //   type: error,
                  //   title: Error,
                  //   description: err,
                  //   useService: true
                  // });
                  data = null;
                  localStorage.removeItem('currentUser');
                  window.location.href = '/login';
                  return throwError(err);
                })
              )
              .toPromise();
          }
          throw error;
        }
      );
    }
  }

  login(payload: any) {
    const newPayload = {
      ...payload,
      // client_id: this.clientId,
      grant_type: 'password',
      // client_secret: 'VpFraMKSgptTeZUz'
    };

    return this.tokenRestService.getToken(newPayload).pipe(
      tap((loginData: any) => {
        this.user = loginData;
        localStorage.setItem('currentUser', JSON.stringify(loginData));
      })
    );
  }

  activateUser(id) {
    return this.tokenRestService.verifyStatus(id);
  }

  verifyEmail(payload) {
    return this.tokenRestService.verifyEmail(payload);
  }

  resetPassword(id, payload) {
    return this.tokenRestService.resetPassword(id, payload);
  }

  refreshToken(payload) {
    return this.tokenRestService.refreshToken(payload);
  }

  logout() {
    data = null;
    localStorage.removeItem('currentUser');
    localStorage.removeItem('origin_contact_address');
    localStorage.removeItem('origin_location');
    localStorage.removeItem('origin_contact_phone');
    window.location.href = '/login';
  }
}
