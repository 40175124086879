import { Table as BaseTable } from 'antd';
import React, { useState, useEffect } from 'react';
import { TableProps } from './table.d';
import { Pagination } from '../../navigation/paginator/paginator.component';

const { Column, ColumnGroup, Summary } = BaseTable;

const Table = (props: TableProps) => {
  const [totalDataSource, setTotalDataSource] = useState(props.pagination?.total || props.dataSource.length);

  function onChangePagination(value) {
    if (props.pagination?.onChange) {
      props.pagination?.onChange(value, 'currentPage');
    }
  }

  useEffect(() => {
    // set based response BE
    if (props.pagination?.total !== totalDataSource) {
      setTotalDataSource(props.pagination?.total);
    }
  }, [props.pagination?.total]);

  return <>
    <BaseTable
      {...props}
      className={'table-deo ' + (props.className || '')}
      pagination={false}
    />
    {
      props.pagination &&
      <Pagination
        {...props.pagination}
        showSizeChanger={false}
        onChange={onChangePagination}
        total={totalDataSource}
        pageSize={props.pagination?.pageSize || 30}
      />
    }
  </>
};

export { Table, Column, ColumnGroup, Summary }
