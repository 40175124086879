import './base-list.component.scss';

import { Badge } from '@clodeo/clodeo-ui/components/data-display/badge/badge.component';
import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Checkbox } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { Drawer } from '@clodeo/clodeo-ui/components/layouts/drawer/drawer.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import {
  Item,
  Menu,
} from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { ExportService } from '@clodeo/libs/core/export/export.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { FormatService } from '@clodeo/clodeo-core/format/format.service';
import { Form } from 'antd';
import _ from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { BaseListProps, IColumn, IExport } from './base-list';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';

function BaseList(props: BaseListProps, ref) {
  const {
    additionalData,
    columns,
    includes,
    loadDataObservable,
    scroll,
    hidePaginations,
    loadingExport,
    rowSelection,
    rowKey,
    summary,
    keyDataSource,
    keyPaginationTake,
    keyPaginationTotal,
    typeFilter,
    totalDataTabel,
    zeroLabelTotal,
    keyExport,
  } = props;
  const notif: Notifications = new Notifications();
  const exportService: ExportService = new ExportService();
  const handleService: HandleService = new HandleService();
  const [formMoreFilter] = Form.useForm();
  const { t } = useTranslation();
  /**
   * State
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingExprt, setLoadingExprt] = useState<{
    status: boolean;
    description: string;
  }>({
    status: false,
    description: '0%',
  });
  const [isShowToggleColumn, setIsShowToggleColumn] = useState<boolean>(false);
  const [showExports, setShowExports] = useState<boolean>(false);
  const [showMoreFilter, setshowMoreFilter] = useState(false);
  const [showMoreFilterDrawer, setshowMoreFilterDrawer] = useState(false);
  const [search, setSearch] = useState<string>();
  const [tableColumns, setColumns] = useState<IColumn[]>(columns);
  const [table, setTable] = useState({
    dataSource: [],
    pagination: {
      options: includes.pagination?.options,
      current: 1,
      pageSize: 30,
      total: 0,
    },
  });
  const [selectedRowRecords, setSelectedRowRecords] = useState<{
    dataPerPage: Object;
    lines: any[];
    keys: string[];
  }>({ dataPerPage: {}, lines: [], keys: [] });

  const onSelectedRowKeys = (selectedKeys, selectedRecords) => {
    let recordsPerParge = { ...selectedRowRecords };
    recordsPerParge['dataPerPage'][table.pagination.current] = selectedRecords;
    recordsPerParge['lines'] = _.flattenDeep(
      Object.values(recordsPerParge['dataPerPage'])
    );
    recordsPerParge['keys'] = _.map(recordsPerParge['lines'], `${rowKey}`);
    setSelectedRowRecords(recordsPerParge);
    props.selectedData(recordsPerParge);
  };

  const thisRef = useRef();

  useImperativeHandle(ref, () => ({
    callLoadData(resetMorefilter?) {
      loadData(resetMorefilter).subscribe();
    },
    resetFilters() {
      resetParams();
    },
    getDefaultParams() {
      return params;
    },
    showLoading(loading: boolean = true) {
      setIsLoading(loading);
    },
    hideLoading() {
      setIsLoading(false);
    },
    onResetMoreFilters() {
      onResetMoreFilter();
    },
    onSubmitDateFilter(values) {
      formMoreFilter.resetFields();
      formMoreFilter.setFieldsValue(values);
      onSubmitMoreFilter(values);
    },
    applyDateFilter(values) {
      includes.dateFilter.action(values);
    },
    resetMoreFilter(namePath?: any | any[]) {
      const val = formMoreFilter.getFieldsValue();
      formMoreFilter.resetFields();
    },
    selectedRows: {
      keys: selectedRowRecords['keys'],
      records: selectedRowRecords['lines'],
    },
    resetPage() {
      const tableValue = table;
      const paramsValue = params;
      paramsValue['skip'] = 0;
      tableValue.pagination.current = 1;
    },

    formMoreFilter,
  }));

  const [selectedColumn, setSelectedColumn] = useState<any[]>(
    _.map(columns || [], 'key')
  );
  const [moreFilterComp, setMoreFilterComp] = useState<any[]>(null);
  const defaultParams = {
    search: search || null,
    take: table.pagination ? table.pagination.pageSize : 0,
    skip: 0,
    includeTotalCount: true,
  };
  const [params, setParams] = useState<any>(defaultParams);

  function resetParams() {
    setSearch('');
    formMoreFilter.resetFields();
    setParams({
      search: null,
      take: table.pagination ? table.pagination.pageSize : 0,
      skip: 0,
      includeTotalCount: true,
    });
  }

  function onChangeToggleColumn(val, idx: number) {
    const newColumn = tableColumns;
    newColumn[idx].className = val
      ? newColumn[idx].className.replace(/[d\-none]+/g, '')
      : `${newColumn[idx].className} d-none`;
    newColumn[idx].hide = !val;
    setColumns([...newColumn]);
  }

  function loadData(resetMorefilter?): Observable<any> {
    setIsLoading(true);
    const moreFilterValue = resetMorefilter
      ? {}
      : formMoreFilter.getFieldsValue(true) || {};
    const qParams = { ...params, ...moreFilterValue };
    return loadDataObservable(qParams).pipe(
      tap((response) => {
        const tableValue = table;
        tableValue.dataSource = keyDataSource
          ? _.get(response, keyDataSource)
          : response.data;
        if (tableValue.pagination) {
          tableValue.pagination.total = keyPaginationTotal
            ? _.get(response, keyPaginationTotal)
            : response.total;
          tableValue.pagination.pageSize = keyPaginationTake
            ? _.get(response, keyPaginationTake)
            : response.take;
        }
        includes.extraButtons &&
          totalDataTabel(_.get(tableValue.pagination, 'total'));
        setTable(tableValue);
        setSelectedRowRecords({ dataPerPage: {}, lines: [], keys: [] });
      }),
      catchError((error) => {
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true,
        });
        return throwError(error);
      }),
      finalize(() => {
        setIsLoading(false);
      })
    );
  }

  function onSubmitMoreFilter(values) {
    if (props.includes.moreFilter && props.includes.moreFilter.onSubmit) {
      props.includes.moreFilter.onSubmit(values);
      return;
    }
    const tableValue = table;
    const paramsValue = params;

    paramsValue['skip'] = 0;
    tableValue.pagination.current = 0;

    setTable({
      ...tableValue,
    });
    setParams(paramsValue);

    setTimeout(() => {
      setshowMoreFilter(false);
      // loadData().subscribe();
    }, 10);
  }

  function onResetMoreFilter() {
    formMoreFilter.resetFields();
    if (includes.moreFilter && includes.moreFilter.onReset) {
      includes.moreFilter.onReset(params);

      if (includes.moreFilter.requestOnRest) {
        loadData().subscribe();
      }
      return;
    }

    if (includes.moreFilter.requestOnRest) {
      loadData().subscribe();
    }
  }

  function MoreFilterComponent() {
    let component = moreFilterComp;

    if (!component) {
      component = React.Children.map(includes.moreFilter.template, (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { form: formMoreFilter } as any);
        }
        return child;
      });
    }
    setMoreFilterComp(component);

    return component;
  }

  useEffect(() => {
    if (onChangeGridData) {
      const subscription = loadData().subscribe();

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [table]);

  useEffect(() => {
    checkingColumn();
  }, [columns]);

  useEffect(() => {
    showMoreFilterDrawer
      ? document.body.classList.add('filter-sidebar')
      : document.body.classList.remove('filter-sidebar');
  }, [showMoreFilterDrawer]);

  const onChangeGridData = (value, type) => {
    const tableValue = table;
    const paramsValue = {
      search: search || null,
      take: table.pagination.pageSize,
      skip: table.pagination.current * table.pagination.pageSize,
      includeTotalCount: true,
      // 'sort': '',
    };
    // use for flag trigger action
    additionalData?.setFieldsValue({
      triggerSource: null
    });

    switch (type) {
      // set take / pageSize params
      case 'pageSize':
        paramsValue['take'] = value;
        // reset current page if pageSize has change
        tableValue.pagination.current =
          value !== _.get(table.pagination, 'pageSize')
            ? 1
            : _.get(table.pagination, 'current');
        tableValue.pagination.pageSize = value;
        paramsValue['skip'] = 0;
        break;

      // set currentPage / skip params
      case 'currentPage':
        paramsValue['skip'] = (value - 1) * _.get(table.pagination, 'pageSize');
        tableValue.pagination.current = value;
        break;

      // when search keyword
      default:
        paramsValue['skip'] = 0;
        // use for flag trigger action
        additionalData?.setFieldsValue({
          triggerSource: 'search'
        });
        break;
    }

    setTable({
      ...tableValue,
    });
    setParams(paramsValue);
  };

  function checkingColumn() {
    const newColumns: IColumn[] = [];

    _.map(columns, function (col) {
      col.className = !col.hide
        ? _.replace(col.className, 'd-none', '')
        : `${col.className} d-none`;
      newColumns.push(col);
    });
    setColumns(newColumns);
  }

  function exportData(propsExp: IExport) {
    const filter = _.omit(
      { ...params, ...formMoreFilter.getFieldsValue(true) },
      ['take', 'skip', 'includeTotalCount']
    );
    if (propsExp.action) {
      propsExp.action({ data: table.dataSource, selectedColumn, filter });
    } else {
      const moreFilterValue = formMoreFilter.getFieldsValue(true) || {};

      let qParams = {
        take: 100,
        skip: 0,
        includeTotalCount: true,
      };

      if (propsExp.basedFilter) {
        qParams = { ...params, ...qParams, ...moreFilterValue };
      }

      if (includes.exportParams) {
        qParams = {
          ...includes.exportParams,
          ...params,
          ...qParams,
          ...moreFilterValue,
        };
      }

      setLoadingExprt({
        status: true,
        description: `0%`,
      });

      // handleService.handleRequest({
      //   obs: loadDataObservable(qParams),
      //   onError: () => {
      //     setLoadingExprt({
      //       status: false,
      //       description: `Error`
      //     });
      //   },
      //   onDone: (response) => {
      //     qParams['take'] = response.data.total;
      //     handleService.handleRequest({
      //       obs: loadDataObservable(qParams),
      //       onError: () => {
      //         setLoadingExprt({
      //           status: false,
      //           description: `Error`
      //         });
      //       },
      //       onDone: ({ data }) => {
      //         if (propsExp.basedFilter) {
      //           exportService.expToXLS({ data, wscols: '' }, propsExp.fileName, tableColumns);

      //         } else {
      //           exportService.expToXLS({ data, wscols: '' }, propsExp.fileName, tableColumns, true);
      //         }

      //         setTimeout(() => {
      //           setLoadingExprt({
      //             status: true,
      //             description: `100%`
      //           });
      //           setTimeout(() => {
      //             setLoadingExprt({
      //               status: false,
      //               description: `100%`
      //             });
      //           }, 150);
      //         }, 50);

      //       }
      //     });
      //   }
      // });
      handleService.handleLoadAllReq({
        obs: loadDataObservable,
        qParams: qParams,
        keyExport: keyExport || null,
        onUpdate: (data) => {
          setLoadingExprt({
            status: true,
            description: `${Math.round(
              (data.totalSkip / data.totalRecord) * 100
            )} %`,
          });
        },
        onError: () => {
          setLoadingExprt({
            status: false,
            description: `Error`,
          });
        },
        onDone: (data) => {
          if (propsExp.basedFilter) {
            exportService.expToXLS(
              { data: data.records, wscols: '' },
              propsExp.fileName,
              tableColumns
            );
          } else {
            exportService.expToXLS(
              { data: data.records, wscols: '' },
              propsExp.fileName,
              tableColumns,
              true
            );
          }
          setTimeout(() => {
            setLoadingExprt({
              status: true,
              description: `100%`,
            });
            setTimeout(() => {
              setLoadingExprt({
                status: false,
                description: `100%`,
              });
            }, 150);
          }, 50);
        },
      });
    }
  }

  const newToggleColumns = (
    <Menu className="m-2">
      {tableColumns.map(
        (column, i) =>
          !column.ignoreDisply && (
            <Item key={`${column.key}`} className="toggle-option-item">
              <Checkbox
                checked={!column.hide}
                onChange={(e) => onChangeToggleColumn(e.target.checked, i)}
              >
                {column.title}
              </Checkbox>
            </Item>
          )
      )}
    </Menu>
  );

  const exportsDropdown = (
    <Menu className="m-2">
      {includes.exports &&
        includes.exports.map((exp, i: number) => {
          return (
            <Item key={i} onClick={() => exportData(exp)}>
              <div className="p-1">{exp.label}</div>
            </Item>
          );
        })}
    </Menu>
  );

  const prefixSearch = () => <i className="icon-deo-search"></i>;

  const drawerTitle = () => (
    <div className="d-flex justify-content-between w-100">
      <div className="title">More Filter</div>
      <div
        className="pointer icon-i-X"
        onClick={() => setshowMoreFilterDrawer(false)}
      ></div>
    </div>
  );

  const filterSectionComponent = () => {
    function onRefresh() {
      if (includes.refreshAction) {
        includes.refreshAction();
      } else {
        loadData().subscribe();
      }
    }

    return (
      <>
        <div
          className={
            'd-flex flex-row flex-wrap justify-content-between filter-section w-100 '
          }
        >
          <div className={`d-flex flex-row flex-wrap col-6 justify-content-between justify-content-lg-start col-lg-${props.listId === 'report' ? '3' : '4'}`}>
            {includes.search && (
              <div
                className={`search align-self-center py-1 px-1 ${
                  includes.moreFilter ? 'col-10' : 'col-6'
                } col-lg-auto`}
              >
                <Input
                  allowClear
                  key="search"
                  placeholder={t('ui.filter.search')}
                  size="large"
                  value={search}
                  onChange={(event) => {
                    setSearch(event.target.value);
                    setParams({ ...params, search: search });
                  }}
                  onPressEnter={onChangeGridData}
                  prefix={prefixSearch()}
                  suffix={
                    includes?.suffixSearch?.show &&
                    includes?.suffixSearch?.contentTemplate
                  }
                />
              </div>
            )}

            {includes.moreFilter && (
              <div
                id="more-filter-button"
                className="align-self-center py-1 px-1"
              >
                <Badge
                  // dot={!_.isEmpty(formMoreFilter.getFieldsValue(true))}
                  // color={
                  //   !_.isEmpty(formMoreFilter.getFieldsValue(true))
                  //     ? '#ff7a7a'
                  //     : null
                  // }
                  size="default"
                  count={
                    (!_.isEmpty(formMoreFilter.getFieldsValue(true)) ||
                      !_.isEmpty(additionalData.getFieldValue('airwaybills'))) && (
                      <CloseOutlined onClick={onResetMoreFilter} />
                    )
                  }
                >
                  <Button
                    id={props.listId + '-more-filter'}
                    icon
                    type="ghosted"
                    iconName="deo-filter"
                    className={`p-2 text-center ${
                      !_.isEmpty(formMoreFilter.getFieldsValue(true))
                        ? 'active'
                        : ''
                    }`}
                    onClick={
                      () => {
                        // document.body.classList.add('filter-sidebar');
                        // setTimeout(() => {
                        setshowMoreFilterDrawer(!showMoreFilterDrawer);
                        // }, 30);
                      }
                      // includes.moreFilter.type == 'drawer'
                      // : setshowMoreFilter(!showMoreFilter)
                    }
                  />
                </Badge>
              </div>
            )}

            {includes.extraComponent && (
              <div className="p-1 align-self-center">
                {includes.extraComponent}
              </div>
            )}
          </div>

          <div className={`d-flex flex-row flex-wrap col-6 justify-content-lg-end align-items-center col-lg-${props.listId === 'report' ? '9' : '8'}`}>
            {props.extraDateFilter && props.extraDateFilterComp}

            {includes.extraButtons && _.isLength(includes.extraButtons.length) && (
              <div
                className={
                  'p-1 align-self-center ' +
                  (includes.extraButtons.length > 1
                    ? 'col-auto'
                    : 'col col-lg-auto')
                }
              >
                {includes.extraButtons.map((extraBtn, i: number) => (
                  <Badge
                    showZero
                    size="small"
                    count={zeroLabelTotal ? '0' : ''}
                    className="badge-bg mx-auto"
                  >
                    <Button
                      key={i}
                      className={
                        includes.extraButtons.length > 1
                          ? `ml-2 ${extraBtn.className} mr-2 px-3 `
                          : `${extraBtn.className} mr-2 px-3 `
                      }
                      id={props.listId + '-extra-btn-' + i}
                      {...extraBtn}
                      onClick={extraBtn.action}
                      type={extraBtn.type || 'basic'}
                    />
                  </Badge>
                ))}
              </div>
            )}

            {includes.importButton && (
              <div className="p-1 align-self-center">
                <Button
                  label="Import"
                  iconName="i-UploadSimple"
                  className="btn-deo-success-base p-2 px-3"
                  onClick={includes.importButton.action}
                  type="basic"
                />
              </div>
            )}

            {((includes.exports && _.isLength(includes.exports.length)) ||
              includes.onlyExportAllData) && (
              <Spinner
                spinning={loadingExport || loadingExprt.status || false}
                tip={loadingExprt.description || ''}
              >
                <div className="px-1 align-self-center py-1 col col-lg-auto">
                  {includes.onlyExportAllData && (
                    <Button
                      id={props.listId + '-exports'}
                      iconName="i-DownloadSimple"
                      label="Export"
                      type="basic"
                      className="btn-deo-danger-base p-2 px-3 text-center h-36"
                      onClick={() => {
                        exportData({
                          basedFilter: false,
                          fileName: includes.fileNameExportAllData,
                        });
                      }}
                    />
                  )}
                  {!includes.onlyExportAllData && (
                    // <Dropdown
                    //   overlay={exportsDropdown}
                    //   placement="bottomLeft"
                    //   trigger={['click']}
                    // >
                    <Button
                      id={props.listId + '-exports'}
                      iconName="i-DownloadSimple"
                      label="Export"
                      type="basic"
                      className="btn-deo-danger-base p-2 px-3 text-center h-36 d-flex align-items-center"
                      onClick={() => {
                        {
                          includes.exports &&
                            includes.exports.map((exp, i: number) => {
                              exportData(exp);
                            });
                        }
                      }}
                    />
                    // </Dropdown>
                  )}
                </div>
              </Spinner>
            )}

            {includes.refresh && (
              <div className="px-1 align-self-center py-1 col col-auto">
                <Button
                  id={props.listId + '-refresh'}
                  icon
                  iconName="i-ArrowClockwise"
                  type="basic"
                  className="btn-deo-refresh-base p-2 text-center"
                  onClick={() => onRefresh()}
                />
              </div>
            )}

            {includes.extraButtonsAfterRefresh &&
              _.isLength(includes.extraButtonsAfterRefresh.length) && (
                <span style={{ color: '#E6ECEE', fontSize: '30px' }}>|</span>
              )}

            {includes.extraButtonsAfterRefresh &&
              _.isLength(includes.extraButtonsAfterRefresh.length) && (
                <div
                  className={
                    'px-1 align-self-center py-1 ' +
                    (includes.extraButtonsAfterRefresh.length > 1
                      ? 'col-auto'
                      : 'col col-lg-auto')
                  }
                >
                  {includes.extraButtonsAfterRefresh.map(
                    (extraBtn, i: number) => (
                      <Button
                        key={i}
                        className={
                          includes.extraButtonsAfterRefresh.length > 1
                            ? `ml-2 ${extraBtn.className} mr-2 px-3`
                            : `${extraBtn.className} mr-2 px-3`
                        }
                        id={props.listId + '-extra-btn-' + i}
                        {...extraBtn}
                        onClick={extraBtn.action}
                        type={extraBtn.type || 'primary'}
                      />
                    )
                  )}
                </div>
              )}

            {includes.toogleOptions && (
              <div className="px-1 align-self-center py-1 col col-auto">
                <Dropdown
                  overlay={newToggleColumns}
                  trigger={['click']}
                  placement="bottomLeft"
                  onVisibleChange={setIsShowToggleColumn}
                  visible={isShowToggleColumn}
                >
                  <Button
                    id={props.listId + '-toggle-columns'}
                    icon
                    iconName="deo-bars-2"
                    type="filter"
                    className="p-2 text-center"
                    onClick={() => setIsShowToggleColumn(!isShowToggleColumn)}
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        <Modal
          width="fit-content"
          className="admin"
          visible={showMoreFilter}
          title="Filters"
          footer={null}
          onCancel={() => setshowMoreFilter(false)}
        >
          {showMoreFilter && (
            <div className="d-flex flex-column w-100">
              <Form form={formMoreFilter} onFinish={onSubmitMoreFilter}>
                <Form.Item shouldUpdate>
                  {() => {
                    return (
                      <>
                        <div className="p-2">
                          {MoreFilterComponent()}
                          {/* {
                        includes.moreFilter.template(formMoreFilter)
                      } */}
                        </div>
                        <div className="p-2 w-100 justify-content-between d-flex justify-content-lg-end">
                          <Button
                            type="ghosted"
                            label="Reset"
                            onClick={onResetMoreFilter}
                            className="mr-1"
                          />
                          <Button
                            submit
                            label="Apply"
                            className="mr-3"
                            type="primary"
                          />
                        </div>
                      </>
                    );
                  }}
                </Form.Item>
              </Form>
            </div>
          )}
        </Modal>
        <Drawer
          title={drawerTitle()}
          onClose={() => setshowMoreFilterDrawer(false)}
          width="250"
          className="drawer-more-filter"
          closable={false}
          placement="left"
          visible={showMoreFilterDrawer}
        >
          <Form form={formMoreFilter} onFinish={onSubmitMoreFilter}>
            {/* <Form.Item shouldUpdate>
              {() => {
                return (
                  <> */}
            <div className="d-flex flex-column justify-content-between">
              <div className="p-2 content-more-filter">
                {/* {showMoreFilterDrawer && MoreFilterComponent()} */}
                {includes.moreFilter &&
                  includes.moreFilter.template(formMoreFilter)}
              </div>
              <div className="p-2 w-100 justify-content-between d-flex justify-content-lg-end">
                <Button
                  type="ghosted"
                  label="Reset"
                  onClick={onResetMoreFilter}
                  className="mr-1"
                />
                <Button submit label="Apply" className="mr-3" type="primary" />
              </div>
            </div>
            {/* </>
                );
              }}
            </Form.Item> */}
          </Form>
        </Drawer>
      </>
    );
  };

  return (
    <div
      className={`no-select-text wrapper-base-list container-fluid p-0 position-relative ${props.className}`}
      ref={thisRef}
      id={props.listId}
    >
      {includes && filterSectionComponent()}
      <Spinner
        className="d-flex align-item-center"
        spinning={isLoading}
        indicator={<LoadingComponent />}
      >
        <Table
          key={'table-' + props.listId}
          rowSelection={
            rowSelection === true
              ? {
                  type: 'checkbox',
                  selectedRowKeys: selectedRowRecords['keys'],
                  columnWidth: '50px',
                  onChange: onSelectedRowKeys,
                }
              : rowSelection || null
          }
          rowKey={rowKey}
          dataSource={table.dataSource}
          columns={table.dataSource && _.reject(tableColumns, 'ignoreDisply')}
          summary={summary}
          pagination={
            !hidePaginations && {
              options: _.get(table.pagination, 'options'),
              total: _.get(table.pagination, 'total'),
              current: _.get(table.pagination, 'current'),
              pageSize: _.get(table.pagination, 'pageSize'),
              onChange: onChangeGridData,
              onChangePageSize: onChangeGridData,
            }
          }
          scroll={scroll}
        />
      </Spinner>
    </div>
  );
}

const BaseListComponent = forwardRef(BaseList);

export { BaseListComponent };
