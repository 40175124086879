import { Pagination as BasePagination } from 'antd';
import React from 'react';
import { Select, Option } from '../../data-entry/select/select.component';
import { Icon } from '../../foundations/icon/icon.component';
import { IPaginationProps } from './paginator';

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <Icon name="deo-arrow-left-1" />;
  }
  if (type === 'next') {
    return <Icon name="deo-arrow-right-1" />;
  }
  return originalElement;
}


const Pagination = (props: IPaginationProps) => {

  const options = props.options || [10, 30, 50, 100];

  function onChangePageSize(value) {
    if (props?.onChangePageSize) {
      props?.onChangePageSize(value, 'pageSize')
    }
  }

  return <div className="table-paginator-wrapper my-3 px-2 flex-wrap">
    <div className="d-flex flex-row justify-content-between mb-2 mb-lg-0">
      <div className="show align-self-center pr-2">
        {'Show'}
      </div>
      <div className="dropdown-paging align-self-center pr-2">
        <Select
          onChange={onChangePageSize}
          value={props.pageSize || 30}
          className="select-paging-deo"
          showArrow={false}
        >
          {
            options.map((val, i: number) => (
              <Option value={val} key={i}>{val}</Option>
            ))
          }
        </Select>
      </div>
      {props.totalSelected !== undefined && (
        <div className="total align-self-center pr-2">
          {
            `Selected: ${props?.totalSelected || 0}`
          }
        </div>
      )}
      <div className="border-paging align-self-center pr-2">
      </div>
      <div className="total align-self-center pr-2">
        {
          `Total ${props?.total || 0} items`
        }
      </div>
      {props?.extraComponent && props?.extraComponent()}
    </div>
    <div>

      <BasePagination
        {...props}
        total={props.total || 1}
        itemRender={itemRender}
        className={'pagination-deo ' + (props.className || '')}
      />
    </div>
  </div>
}
export { Pagination };
