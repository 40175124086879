import _ from 'lodash';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import {
  IHandleRequest,
  IHandleLoadAllRequest,
  IDataLoadAllRequest,
} from './handle.d';

const notif = new Notifications();

export class HandleService {
  dataLoadAll: IDataLoadAllRequest = {
    records: [],
    totalSkip: 0,
    totalRecord: 0,
  };

  handleRequest(params: IHandleRequest) {
    const {
      obs,
      hideError,
      successMessage,
      errorMessage,
      onDone,
      onError,
      handleTap,
    } = params;
    return obs
      .pipe(
        catchError((error) => {
          const errs =
            _.get(error.response, 'data.errors[0].error_message', '') || error;
          if (!hideError) {
            notif.show({
              type: 'error',
              title: 'Error',
              description: errorMessage || errs,
              useService: true,
            });
          }
          const errData = _.get(error, 'response.data');
          onError && onError(errData || '');
          return throwError(error);
        }),
        tap((response) => {
          handleTap && handleTap(response);
        })
      )
      .subscribe((res) => {
        successMessage &&
          notif.show({
            type: 'success',
            title: 'Sukses',
            description: successMessage,
          });
        onDone && onDone(res);
      });
  }

  handleLoadAllReq(data: IHandleLoadAllRequest) {
    let params = { ...data.qParams };
    const {
      obs,
      hideError,
      successMessage,
      errorMessage,
      onDone,
      onError,
      onUpdate,
      handleTap,
      method,
      clearOnDone,
      keyExport,
    } = data;

    return obs(params)
      .pipe(
        catchError((error) => {
          const errs =
            _.get(error.response, 'data.errors[0].error_message', '') || error;
          if (!hideError) {
            notif.show({
              type: 'error',
              title: 'Error',
              description: errorMessage || errs,
              useService: true,
            });
          }
          const errData = _.get(error, 'response.data');
          onError && onError(errData || '');
          return throwError(error);
        }),
        tap((response) => {
          handleTap && handleTap(response);
        })
      )
      .subscribe((response) => {
        let keyResponseToExport = [];
        if (keyExport) {
          keyResponseToExport = _.get(response, keyExport);
        } else {
          keyResponseToExport = response.data['list'];
        }

        successMessage &&
          notif.show({
            type: 'success',
            title: 'Sukses',
            description: successMessage,
          });
        this.dataLoadAll.records = [
          ...this.dataLoadAll.records,
          ...keyResponseToExport,
        ];
        this.dataLoadAll.totalSkip += response.data.take || 0;
        this.dataLoadAll.totalRecord = response.data.total || 0;
        if (this.dataLoadAll.totalSkip < this.dataLoadAll.totalRecord) {
          switch (method) {
            case 'post':
              params.skip = this.dataLoadAll.totalSkip;
              break;
            default:
              params['skip'] = this.dataLoadAll.totalSkip;
              break;
          }
          data.qParams = params;
          this.handleLoadAllReq(data);
          onUpdate && onUpdate(this.dataLoadAll);
        } else {
          this.handleLoadAllReq(data).unsubscribe();
          this.dataLoadAll.totalSkip = this.dataLoadAll.totalRecord;
          onDone && onDone(this.dataLoadAll);
          if (clearOnDone) {
            this.dataLoadAll = {
              records: [],
              totalSkip: 0,
              totalRecord: 0,
            };
          }
        }
      });
  }
}
