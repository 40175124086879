import { RequestService } from '../../request.service';

export class SampoernaPreOrderRestService {
  requestService = new RequestService();
  request: RequestService;

  constructor(
    baseUrl: any,
    useInterceptor: any = true,
    withoutInterceptor: any = false
  ) {
    this.request = this.requestService.new(
      `${baseUrl['API_MAIN']}`,
      useInterceptor,
      withoutInterceptor
    );
  }
  getManagePoList(query?) {
    return this.request.get(`v1/pre-order`, {
      params: {
        ...query,
      },
    });
  }
  getPreOrderDetail(query?) {
    return this.request.post(`v1/pre-order/detail/${query}`);
  }

  getOrders(params?) {
    return this.request.get('v1/orders', {
      params: params,
    });
  }

  getCompany(params) {
    return this.request.get('v1/company/list', { params });
  }

  createPreOrder(payload) {
    return this.request.post('v1/pre-order/create', {
      ...payload,
    });
  }

  getSpv(params?) {
    return this.request.get('/user/get-spv', {
      params: {
        ...params,
      },
    });
  }

  getBalance(id: string) {
    return this.request.get(`v1/pre-order/${id}/balance`);
  }

  getPONumber(po: string) {
    return this.request.get(`/v1/pre-order/${po}`);
  }
}
