import React, { Suspense } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { GuardRoute } from '../../components/guard-route/guard-route.component';
import { MainLayoutComponent } from '../../components/layout-component/main/main-layout.component';
import { LazyPageComponent } from '@clodeo/clodeo-ui/components/page/lazy/lazy-page.component';
import PoManagementComponent from './po/po-management/po-management.component';
import PoDetails from './po/po-management/details/po-details';

const OrderComponent = React.lazy(() => import('./order/order.component'));
const OrderDetailComponent = React.lazy(
  () => import('./order/detail/order-detail.component')
);
const OrderCreateComponent = React.lazy(
  () => import('./order/create/order-create.component')
);
const OrderUpdateComponent = React.lazy(
  () => import('./order/update/order-update.component')
);
const OrderImportComponent = React.lazy(
  () => import('./order/import/order-import.component')
);
const ContactComponent = React.lazy(
  () => import('./contact/contact.component')
);
const ContactImportComponent = React.lazy(
  () => import('./contact/import/contact-import.component')
);
const UserListComponent = React.lazy(
  () => import('./user/list/user-list.component')
);
const UserListImportComponent = React.lazy(
  () => import('./user/list/import/user-list-import.component')
);
const ReportComponent = React.lazy(
  () => import('./po/report/report.component')
);

export function MainRouter() {
  let { path, url } = useRouteMatch();
  return (
    <>
      <MainLayoutComponent>
        <Suspense fallback={<LazyPageComponent />}>
          <Switch>
            <GuardRoute title="Order" path={`${url}/order`} meta={{ permissions: ['manage-order'] }} exact>
              <OrderComponent />
            </GuardRoute>
            <GuardRoute title="Order Import" path={`${url}/order/import`} exact>
              <OrderImportComponent />
            </GuardRoute>
            <GuardRoute title="Order Create" path={`${url}/order/create`} meta={{ permissions: ['manage-order'] }} exact>
              <OrderCreateComponent />
            </GuardRoute>
            <GuardRoute title="Order update" path={`${url}/order/:id/update`} meta={{ permissions: ['manage-order'] }} exact>
              <OrderUpdateComponent />
            </GuardRoute>
            <GuardRoute title="Order Detail" path={`${url}/order/:id`} meta={{ permissions: ['manage-order'] }} exact>
              <OrderDetailComponent />
            </GuardRoute>
            <GuardRoute title="ui.menu.contact" path={`${url}/contact`} meta={{ permissions: ['manage-contact'] }} exact>
              <ContactComponent />
            </GuardRoute>
            <GuardRoute title="Report" path={`${url}/report`} meta={{ permissions: ['manage-report'] }} exact>
              <ReportComponent />
            </GuardRoute>
            <GuardRoute title="Contact Import" path={`${url}/contact/import`} meta={{ permissions: ['manage-contact'] }} exact>
              <ContactImportComponent />
            </GuardRoute>
            <GuardRoute title="User List" path={`${url}/user`} meta={{ permissions: ['manage-user'] }} exact>
              <UserListComponent />
            </GuardRoute>
            <GuardRoute title="Po Management" path={`${url}/po-management`} meta={{ permissions: ['manage-po'] }} exact>
              <PoManagementComponent />
            </GuardRoute>
            <GuardRoute title="Po Management" path={`${url}/po-management/details/:id`} meta={{ permissions: ['manage-po'] }} exact>
              <PoDetails type="manage-po" />
            </GuardRoute>
            <GuardRoute title="Report" path={`${url}/report/detail/:id`} meta={{ permissions: ['manage-report'] }} exact>
              <PoDetails type="manage-report" />
            </GuardRoute>
          </Switch>
        </Suspense>
      </MainLayoutComponent>
    </>
  );
}
