import React, { useRef, useState } from 'react';
import { Upload } from 'antd';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { AuthenticationService } from 'apps/sampoerna/src/app/core/auth/authentication.service';
import { environment } from 'apps/sampoerna/src/environments/environment';
import './attachment-field.component.scss';
import { IAttachmentField } from './attachment-field';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';

export default function AttachmentFieldComponent(props: IAttachmentField) {
  const { form, placeholder } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const authenticationService: AuthenticationService = new AuthenticationService();
  const headerFile = {
    Authorization: `Bearer ${authenticationService.user.access_token}`,
  };

  const buttonUploadRef = useRef(null);
  const [nameFile, setNameFile] = useState<string>('');

  const handleUploadChange = (e) => {
    setIsLoading(true);
    const file = e?.file?.originFileObj;
    if (e?.file?.status === 'done') {
      setIsLoading(false);
      setNameFile(file.name);
      form.setFieldsValue({
        attachmentId: e?.file?.response?.data?.id,
      });
    }
  };

  return (
    <div>
      <Spinner spinning={isLoading}>
        <Upload
          onChange={handleUploadChange}
          ref={buttonUploadRef}
          className="w-100 d-block create-order"
          headers={headerFile}
          action={environment.ENDPOINTS.API_FILE}
        >
          <Input readOnly value={nameFile} placeholder={placeholder} />
        </Upload>
      </Spinner>
    </div>
  );
}
