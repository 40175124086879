import { BaseListComponent } from 'apps/sampoerna/src/app/components/base-component/list/base-list.component';
import React, { useContext, useRef } from 'react';
import {
  Option,
  Select,
} from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import {
  IColumn,
  IIncludes,
} from 'apps/sampoerna/src/app/components/base-component/list/base-list';
import { filter, get, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { IPoDetailsComponentProps } from './po-details-component';
import moment, { Moment } from 'moment';
import { PODetailContext } from '../../../po-management/details/po-details';

export default function PoDetailsComponent(props: IPoDetailsComponentProps) {
  const formatService = new FormatService();
  const { dateFilterValue, filterType, setDateFilterValue, setFilterType } = useContext(PODetailContext);

  const baseListRef: any = useRef();
  const columns: IColumn[] = [
    {
      dataIndex: 'tracking_info',
      title: 'No. Resi',
      render: (val, record) => (
        <>
          {props.type === 'manage-report' ? (
            <Link to={`/features/order/${record?._id}`}>
              <span
                style={{
                  color: '#3f8cff',
                }}
              >
                {val?.airwaybill}
              </span>
            </Link>
          ) : (
            <span
              style={{
                color: '#3f8cff',
              }}
            >
              {val?.airwaybill}
            </span>
          )}
        </>
      ),
      formatExprt: (val) => val?.airwaybill,
    },
    {
      dataIndex: 'delivery_time',
      title: 'Tanggal Pengiriman',
      render: (val, record) => (
        <div className="row mt-1">
          <div className="col  m-15">
            {val ? moment(val).format('DD/MM/yyyy') : '-'}
          </div>
        </div>
      ),
      formatExprt: (val) => (val ? moment(val).format('DD/MM/yyyy') : '-'),
    },
    {
      dataIndex: 'order_histories',
      title: 'Tanggal Terima',
      ignoreDisply: true,
      formatExprt: (value, record) => {
        let result = '';
        const deliveredDate = filter(value, ['status', 6]);
        if (deliveredDate.length) {
          result = moment.utc(get(deliveredDate, '[0].status_date')).format('DD/MM/YYYY');
        }
        return result || '-';
      },
    },
    {
      dataIndex: 'service',
      title: 'Layanan',
      render: (val) => val,
      formatExprt: (val) => val,
    },
    {
      dataIndex: 'origin',
      title: 'Nama Pengirim',
      render: (val) => val?.contact?.name,
      formatExprt: (val) => val?.contact?.name,
    },
    {
      dataIndex: 'origin',
      title: 'Asal Pengiriman',
      ignoreExprt: true,
      render: (val) => <span>{`${FormatService.forceStartCase(val?.subdistrict_name)}, ${FormatService.forceStartCase(val?.city_name)}`}</span>
    },
    {
      dataIndex: 'origin',
      title: 'Kota Asal',
      ignoreDisply: true,
      formatExprt: (val) => FormatService.forceStartCase(val?.city_name),
    },
    {
      dataIndex: 'origin',
      title: 'Kecamatan Asal',
      ignoreDisply: true,
      formatExprt: (val) => FormatService.forceStartCase(val?.subdistrict_name),
    },
    {
      dataIndex: 'destination',
      title: 'Nama Penerima',
      render: (val) => val?.contact?.name,
      formatExprt: (val) => val?.contact?.name,
    },
    {
      dataIndex: 'destination',
      title: 'Tujuan Pengiriman',
      ignoreExprt: true,
      render: (val) => <span>{`${FormatService.forceStartCase(val?.subdistrict_name)}, ${FormatService.forceStartCase(val?.city_name)}`}</span>
    },
    {
      dataIndex: 'destination',
      title: 'Kota Tujuan',
      ignoreDisply: true,
      formatExprt: (val) => FormatService.forceStartCase(val?.city_name),
    },
    {
      dataIndex: 'destination',
      title: 'Kecamatan Tujuan',
      ignoreDisply: true,
      formatExprt: (val) => FormatService.forceStartCase(val?.subdistrict_name),
    },
    {
      dataIndex: 'transaction',
      title: 'Berat (kg)',
      render: (val) => val?.weight,
      formatExprt: (val) => val?.weight,
    },
    {
      dataIndex: 'transaction',
      title: 'Ongkir (Rp)',
      render: (val) => formatService.currency(val?.shipping_charge),
      formatExprt: (val) => val?.shipping_charge,
    },
    {
      dataIndex: 'actualPriceWeight',
      title: 'Berat Aktual (Kg)',
      render: (val) => (!isNil(val) ? val?.actualWeight : '-'),
      formatExprt: (val) => (!isNil(val) ? val?.actualWeight : '-'),
    },
    {
      dataIndex: 'actualPriceWeight',
      title: 'Aktual Ongkir (Rp)',
      render: (val) =>
        !isNil(val) ? formatService.currency(val?.totalPrice) : '-',
      formatExprt: (val) =>
        !isNil(val) ? val?.totalPrice : '-',
    },
    {
      dataIndex: 'preOrder',
      title: 'No. PO',
      ignoreDisply: true,
      formatExprt: (val) => val?.poNumber,
    },
    {
      dataIndex: 'user_created',
      title: 'Nama Pengguna',
      render: (val) => val?.fullName || '',
      formatExprt: (val) => val?.fullName || '',
    },
  ];

  const includes: IIncludes = {
    refresh: true,
    search: true,
    exports: [
      {
        label: 'Export',
        fileName: 'Detail Nomor PO',
        basedFilter: true,
      },
    ],
    // onlyExportAllData: true,
    // fileNameExportAllData: 'Detail Nomor PO',
  };

  const dateFilterType = [
    {
      label: 'Create Order',
      value: 'Date',
    },
    {
      label: 'Pickup Date',
      value: 'Pickup',
    },
    {
      label: 'Delivered Date',
      value: 'Dlvrd',
    },
  ];

  const sliceLocaleString = (date: string) => {
    return date.slice(0, 10);
  };

  const setDateFilter = (e?: [Moment, Moment], type?) => {
    if (e !== undefined) {
      setDateFilterValue(e);
    }

    let startDate;
    let endDate;

    if (e !== null) {
      startDate = sliceLocaleString(
        e ? e[0].format('YYYY-MM-DD') : dateFilterValue[0].format('YYYY-MM-DD')
      );
      endDate = sliceLocaleString(
        e ? e[1].format('YYYY-MM-DD') : dateFilterValue[1].format('YYYY-MM-DD')
      );
    } else {
      startDate = null;
      endDate = null;
    }

    let startDateKey;
    let endDateKey;
    if (type) {
      startDateKey = `startDate${type === 'Date' ? '' : type}`;
      endDateKey = `endDate${type === 'Date' ? '' : type}`;
    } else {
      startDateKey = `startDate${filterType === 'Date' ? '' : filterType}`;
      endDateKey = `endDate${filterType === 'Date' ? '' : filterType}`;
    }
    const filterRangeDate = {
      [startDateKey]: startDate,
      [endDateKey]: endDate,
    };
    baseListRef.current.onSubmitDateFilter(filterRangeDate);
  };

  const onSelectDateFilterType = (e) => {
    setFilterType(e);
    setDateFilter(undefined, e);
  };
  const DateFilterComp = () => {
    return (
      <div className="d-flex mr-2">
        <Select
          defaultValue={filterType}
          className="mr-2 h-fit extra-date-filter"
          onChange={onSelectDateFilterType}
          style={{
            width: 'calc(112px + 11px + 11px)',
          }}
        >
          {dateFilterType.map((el, i) => (
            <Option key={i} value={el.value}>
              {el.label}
            </Option>
          ))}
        </Select>
        <RangePicker
          className="date-filter-includes no-shadow h-36"
          value={dateFilterValue}
          onChange={(e) => {
            setDateFilter(e);
          }}
          style={{
            width: '216px',
          }}
        />
      </div>
    );
  };

  return (
    <div>
      {props.head}
      <BaseListComponent
        columns={props.columns ? props.columns : columns}
        includes={props.includes ? props.includes : includes}
        loadDataObservable={props.dataList}
        className="mt-4"
        keyDataSource="data.list"
        keyPaginationTotal="data.total"
        keyPaginationTake="data.take"
        // keyExport="data.orders.list"
        extraDateFilter={true}
        listId="poDetail"
        ref={baseListRef}
        extraDateFilterComp={<DateFilterComp />}
      />
    </div>
  );
}
