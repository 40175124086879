import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  GuardRoute,
  ProvideAuth,
} from './components/guard-route/guard-route.component';
import { GuestRoute } from './components/guest-route/guest-route.component';

const LoginComponent = React.lazy(
  () => import('./features/login/login.component')
);
const NotFoundComponent = React.lazy(
  () => import('./features/not-found/not-found.component')
);
const ForbiddenAccessComponent = React.lazy(
  () => import('./features/forbidden-access/forbidden-access.component')
);
const ForgotPasswordComponent = React.lazy(
  () => import('./features/forgot-password/forgot-password.component')
);
const RegisterComponent = React.lazy(
  () => import('./features/register/register.component')
);
const ResetPasswordComponent = React.lazy(
  () => import('./features/reset-password/reset-password.component')
);
import { MainRouter } from './features/main/main-router';

export function AppRouter() {
  return (
    <>
      <ProvideAuth>
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <GuardRoute path="/features">
                <MainRouter />
              </GuardRoute>
              <GuardRoute path="/" exact></GuardRoute>
              <GuestRoute path="/login" exact component={LoginComponent} />
              <GuestRoute
                path="/register"
                exact
                component={RegisterComponent}
              />
              <GuestRoute
                path="/forgot-password"
                exact
                component={ForgotPasswordComponent}
              />
              <GuestRoute
                path="/reset-password"
                exact
                component={ResetPasswordComponent}
              />
              <Route path="/404" component={NotFoundComponent} />
              <Route path="/403" component={ForbiddenAccessComponent} />
              <Route path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </ProvideAuth>
    </>
  );
}
