import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExt = '.xlsx';

export class ExportService {
  expToXLS(
    csv: { data: any; wscols: any },
    fileName,
    columns?,
    ignoreHideColumn?
  ) {
    let records = [...csv.data];
    if (columns) {
      records = this.prepareHeaders(records, columns, ignoreHideColumn);
    }

    const ws = XLSX.utils.json_to_sheet(records);
    ws['!cols'] = csv.wscols;
    // ws["!cols"][15] = { hidden: true };
    // ws["!cols"][16] = { hidden: true };
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExt);
  }

  prepareHeaders(data, columns, ignoreHideColumn?) {
    const newData = [];
    data.map((record, key) => {
      let newRecord = {};
      for (let index = 0; index < columns.length; index++) {
        const valueColumn = _.get(record, columns[index].dataIndex);
        // const hasIndex = _.has(record, columns[index].dataIndex);
        const isIgnoreExport = columns[index].ignoreExprt;
        if (!columns[index].hide && !isIgnoreExport) {
          newRecord = this.setValueColumn(
            newRecord,
            columns[index],
            valueColumn,
            record
          );
        } else if (ignoreHideColumn && !isIgnoreExport) {
          newRecord = this.setValueColumn(
            newRecord,
            columns[index],
            valueColumn,
            record
          );
        }
      }
      newData.push(newRecord);
    });
    return newData;
  }

  setValueColumn(tempObj, column, value, obj) {
    if (column.formatExprt) {
      tempObj[column.title] = column.formatExprt(value, obj);
    } else {
      tempObj[column.title] = value;
    }
    return tempObj;
  }
}
