import { Checkbox as BaseCheckbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import React from 'react';
import { CheckBoxProps } from './checkbox';
const [BaseCheckboxGroup] = [BaseCheckbox.Group]
const Checkbox = (props: CheckBoxProps) => (
  <BaseCheckbox
    {...props}
    className={`checkbox-deo ${props.variant || 'basic'} ${props.className || ''}`}
  />
)

const CheckboxGroup = (props: CheckboxGroupProps) => (
  <BaseCheckboxGroup
    {...props}
    className={`checkbox-group-deo ${props.className}`}
  />
)
export {
  Checkbox,
  CheckboxGroup
}