import './header-layout.component.scss';

import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';
import {
  TabPane,
  Tabs,
} from '@clodeo/clodeo-ui/components/data-display/tabs/tabs.component';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import {
  Item,
  Menu,
} from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Avatar } from '@clodeo/clodeo-ui/components/ui-elements/avatar/avatar.component';
import i18n from '@clodeo/libs/core/translate/i18n';
import { Layout } from 'antd';
import Cookies from 'js-cookie';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  AuthenticationService,
  MUser,
} from '../../../core/auth/authentication.service';

const { Header } = Layout;
const authService: any = new AuthenticationService();
export const HeaderLayoutComponent = (props: {
  pathName?: string;
  menus: IMenuRf[];
  parentMenu: IMenuRf;
  isSubmenu: boolean;
}) => {
  const [title, setTitle] = useState<string>('');
  const [isActiveTab, setIsActiveTab] = useState<string>(props.pathName);
  const [user, setUser] = useState<MUser & { name: string }>({
    ...authService.user,
    name: _.startCase(authService.user.user.fullName),
  });
  const router = useHistory();
  const { isSubmenu, parentMenu } = props;
  const { t } = useTranslation();

  function onLogout() {
    authService.logout();
  }

  useEffect(() => {
    // setTitle(props.title);
    let link = props.pathName;
    // if (props.pathName.includes(m.to)) {
    //   setTitle(m.label);
    //   link = m.to;
    // }
    if (parentMenu?.subs) {
      parentMenu?.subs.map((m) => {
        if (props.pathName.includes(m.to)) {
          setTitle(m.label);
          link = m.to;
        }
      });
    } else {
      if (props.pathName.includes(parentMenu?.to)) {
        setTitle(parentMenu?.label);
        link = parentMenu?.to;
      }
    }
    setIsActiveTab(link);
  }, [props.parentMenu, props.pathName]);

  function redirectTo(to: string) {
    router.push(to);
  }

  const dropdown = (
    <Menu>
      <Item onClick={() => onLogout()}>Sign Out</Item>
    </Menu>
  );

  const translateDropdown = (
    <div className="translate-dropdown pl-2 pt-2">
      <p
        style={{ cursor: 'pointer' }}
        onClick={() => handleChangeLanguage('en')}
      >
        English
      </p>
      <p
        style={{ cursor: 'pointer' }}
        onClick={() => handleChangeLanguage('id')}
      >
        Indonesian
      </p>
    </div>
  );

  function handleChangeLanguage(lang) {
    let cookiesLang = 'id';
    switch (lang) {
      case 'en':
        i18n.changeLanguage('en');
        cookiesLang = lang;
        break;
      case 'id':
        i18n.changeLanguage('id');
        cookiesLang = lang;
        break;
      default:
        break;
    }
    Cookies.set('i18nLang', cookiesLang, { expires: 7 });
  }

  return (
    <>
      <Header className="no-select-text header-admin-deo px-3 pt-2 pb-1">
        <div className="w-100 m-auto row justify-content-between border-header">
          <div className="col-lg-auto col-auto order-1 profile-section align-self-start">
            <div className="d-flex flex-row justify-content-end justify-content-lg-end py-2 py-lg-0">
              <div className="notification mx-3">
                <Popover
                  placement="bottom"
                  title="Notification"
                  arrowPointAtCenter
                  // content={<NotificationComponent />}
                  trigger="click"
                >
                  <a>
                    <Icon name="i-Bell" />
                  </a>
                </Popover>
              </div>
              <div className="translate mx-3">
                <Popover
                  placement="bottom"
                  title={'Translate'}
                  arrowPointAtCenter
                  content={translateDropdown}
                  trigger="click"
                >
                  <a>
                    <Icon name="i-Globe" />
                  </a>
                </Popover>
              </div>
              <div className="d-flex flex-column justify-content-center ml-3">
                <div className="profile">
                  <Avatar type="circle" />
                  <div className="ml-lg-3 align-self-center">
                    <Dropdown
                      overlay={dropdown}
                      placement="bottomRight"
                      arrow
                      trigger={['click']}
                    >
                      <span className="username">
                        <span className="d-none d-lg-inline">{user.name}</span>
                        <Icon name="deo-arrow-down-1" className="ml-4" />
                      </span>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-auto col-auto order-0 d-flex flex-row">
            {parentMenu?.subs?.length && (
              <div className="title-page-header align-self-center my-auto">
                {t(parentMenu.label) || ''} / {t(title) || ''}
              </div>
            )}
            {!parentMenu?.subs?.length && (
              <div className="title-page-header align-self-center my-auto">
                {t(title) || ''}
              </div>
            )}
          </div>
          <div className="col-12 order-2 d-flex flex-row">
            {isSubmenu && (
              <Tabs onChange={redirectTo} activeKey={isActiveTab}>
                {parentMenu?.subs.map((m) => {
                  return <TabPane tab={t(m.label)} key={m.to} />;
                })}
              </Tabs>
            )}
          </div>
        </div>
      </Header>
    </>
  );
};
