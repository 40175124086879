import React, { useEffect, useState } from 'react';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import {
  Form,
  FormInstance,
} from '@clodeo/clodeo-ui/components/form/antd-form.component';
import { environment } from 'apps/sampoerna/src/environments/environment';
import { AutoComplete } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { SampoernaPreOrderRestService } from '@clodeo/libs/core/rest/sampoerna/pre-order/sampoerna-pre-order.service';
import { AuthenticationService } from 'apps/sampoerna/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

interface ISpvField {
  className?: string;
  style?: any;
  target: string;
  placeholder?: string;
  form?: FormInstance;
}
export default function SpvUserFieldComponent(props: ISpvField) {
  const handleService: HandleService = new HandleService();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const sampoernaManagePoRestService = new SampoernaPreOrderRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [spvList, setSpvList] = useState<any>([{}]);

  const handleOnClear = () => {
    setSpvList([]);

    props.form.setFieldsValue({
      spvName: null,
      [props.target]: null,
    });
  };

  const loadSpvData = (params?: any) => {
    setLoading(true);
    setSpvList([]);
    const obs = sampoernaManagePoRestService.getSpv(params);
    handleService.handleRequest({
      obs,
      onDone: (res) => {
        setLoading(false);
        const dataList = [];
        res.data.map((spv) => {
          dataList.push({
            label: spv.fullName,
            value: spv.fullName,
            spvId: spv._id,
          });
        });
        setSpvList(dataList);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  const searchSpv = (val) => {
    const urlParams = {
      search: val,
    };
    if (val.length > 2) {
      loadSpvData(urlParams);
    } else if (val.length === 0) {
      loadSpvData();
    }
  };

  const onSelectSpv = (val, spvOption) => {
    props.form.setFieldsValue({
      spvName: spvOption.value,
      [props.target]: spvOption.spvId,
    });
  };

  let delaySearch;

  function handleOnSearch(value) {
    clearTimeout(delaySearch);

    delaySearch = setTimeout(() => {
      searchSpv(value);
    }, 1000);
  }

  useEffect(() => {
    loadSpvData();
  }, []);
  return (
    <>
      <Spinner spinning={loading}>
        <AutoComplete
          options={spvList}
          placeholder={props.placeholder}
          onClear={handleOnClear}
          onSelect={onSelectSpv}
          onSearch={handleOnSearch}
          style={props.style}
          className={props.className}
        />
      </Spinner>
    </>
  );
}
