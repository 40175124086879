export const environment = {
  company: 'sampoerna',
  CONTENTS: {
    TITLE: 'Sampoerna',
    CLIENT_ID: 'sampoerna',
    COMPANY_LOGO: 'https://nx-design-system-web-development.clodeo.com/img/logo/sampoerna/sampoerna.svg',
    SMALL_COMPANY_LOGO: 'https://nx-design-system-web-development.clodeo.com/img/logo/sampoerna/small-sampoerna.svg',
    WHITE_COMPANY_LOGO: 'https://nx-design-system-web-development.clodeo.com/img/logo/sampoerna/sampoerna.svg',
    APP_COLOR: '#F4F9FD',
    FAVICON: 'https://nx-design-system-web-development.clodeo.com/img/icon/sampoerna/sampoerna.ico',
    COURIERS: ['sicepat'],
    WHITELABELID: '6194afa68ff20b865d5a1786',
  },
  ENDPOINTS: {
    API_ACCOUNT: 'https://auth-api-development.shipdeo.com',
    API_ADMIN: 'https://admin-api-development.shipdeo.com/v1',
    API_MAIN: 'https://sampoerna-api-development.shipdeo.com',
    API_FILE: 'https://sampoerna-api-development.shipdeo.com/v1/file/upload-custom',
    API_SHIPDEO_MAIN: 'https://main-api-development.shipdeo.com/v1',
  },
  environmentName: 'local',
  production: true,
};