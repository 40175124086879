import React from 'react';
import PoListComponent from './list/po-list.component';
import 'apps/sampoerna/src/assets/styles/component/modal/_modal.scss';

export default function PoManagementComponent(props) {
  return (
    <div>
      <PoListComponent />
    </div>
  );
}
