import { AutoComplete } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { SampoernaPreOrderRestService } from '@clodeo/libs/core/rest/sampoerna/pre-order/sampoerna-pre-order.service';
import { FormInstance } from 'antd';
import { AuthenticationService } from 'apps/sampoerna/src/app/core/auth/authentication.service';
import { environment } from 'apps/sampoerna/src/environments/environment';
import React, { useEffect, useState } from 'react';

interface ICompanyField {
  className?: string;
  form: FormInstance;
  name: string;
  placeholder?: string;
}

let delaySearch;
export default function CompanyFieldComponent(props: ICompanyField) {
  const handleService: HandleService = new HandleService();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const sampoernaManagePoRestService = new SampoernaPreOrderRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [companyList, setCompanyList] = useState<any>([]);

  function loadCompany(search?: any) {
    setLoading(true);
    setCompanyList([]);

    const payload = {
      take: 30,
      skip: 0,
      search: search || null
    }

    const obs = sampoernaManagePoRestService.getCompany(payload);
    handleService.handleRequest({
      obs,
      onDone: (response) => {
        const listCompany = response.data.list.map(val => ({ id: val._id, label: val.companyName, value: val.companyName }));
        setCompanyList(listCompany);
        setLoading(false);
      },
      onError: () => setLoading(false)
    })
  }

  function handleOnClear() {
    props.form.setFieldsValue({
      companyName: null,
      [props.name]: null
    });

    loadCompany();
  }

  function handleOnSelect(value, option) {
    props.form.setFieldsValue({
      companyName: option.value,
      [props.name]: option.id
    });
  }

  function handleOnSearch(value) {
    clearTimeout(delaySearch);

    delaySearch = setTimeout(() => {
      loadCompany(value);
    }, 1000);
  }

  useEffect(() => {
    loadCompany();
  }, []);

  return (
    <Spinner spinning={loading}>
      <AutoComplete
        options={companyList}
        placeholder={props.placeholder}
        onClear={handleOnClear}
        onSelect={handleOnSelect}
        onSearch={handleOnSearch}
        className={props.className}
      />
    </Spinner>
  )
}