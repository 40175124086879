import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRouter } from './app-router';
import './app.scss';
import '@clodeo/libs/core/translate/i18n';
import { isNil } from 'lodash';
import { environment } from '../environments/environment';
import Favicon from 'react-favicon';
import DocumentMeta from 'react-document-meta';

import { UserVariableService } from './core/auth/user-variable/user-variable.service';
import { AuthenticationService } from './core/auth/authentication.service';
import { AclService } from './core/auth/acl.service';

const authenticationService: AuthenticationService = new AuthenticationService();
const aclService: AclService = new AclService();

const userVariableService: UserVariableService = new UserVariableService();

const App = () => {
  const router = useHistory();

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      if (event.key === 'currentUser') {
        if (isNil(event.newValue)) {
          authenticationService.logout();
        } else {
          const data = JSON.parse(event.newValue);
          if (data.user.hasCompany) {
            authenticationService.user = data;
            aclService.redirectAllowedMenu(router, true);
          } else {
            router.push('/register-company');
          }
        }
      }
    });
  }, [])

  return (
    <DocumentMeta title={environment.CONTENTS.TITLE}>
      <Favicon url={environment.CONTENTS.FAVICON} />
      <AppRouter />
    </DocumentMeta>
  );
}

export default App;
