import { FormatService } from '@clodeo/libs/core/format/format.service';
import {
  IColumn,
  IIncludes,
} from 'apps/sampoerna/src/app/components/base-component/list/base-list';
import { BaseListComponent } from 'apps/sampoerna/src/app/components/base-component/list/base-list.component';
import { SampoernaPreOrderRestService } from '@clodeo/libs/core/rest/sampoerna/pre-order/sampoerna-pre-order.service';
import { AuthenticationService } from 'apps/sampoerna/src/app/core/auth/authentication.service';
import { environment } from 'apps/sampoerna/src/environments/environment';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Option,
  Select,
} from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import './po-list.component.scss';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Moment } from 'moment';
import PoFormCreateComponent from '../create/po-form-create.component';
import { Form } from '@clodeo/clodeo-ui/components/form/antd-form.component';
import { useTranslation } from 'react-i18next';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';

export default function PoListComponent(props) {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const sampoernaManagePoRestService = new SampoernaPreOrderRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const formatService = new FormatService();
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);
  const [createPoForm] = Form.useForm();
  const { t } = useTranslation();
  const baseListRef: any = useRef();
  const [dateFilterType, setDateFilterType] = useState<string>('Date');
  const [query, setQuery] = useState<any>({});
  const [dateFilterValue, setDateFilterValue] = useState<[Moment, Moment]>();
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const handleService: HandleService = new HandleService();
  const notification: Notifications = new Notifications();

  function loadDataObservable(params) {
    const obs = sampoernaManagePoRestService.getManagePoList({
      ...params,
      ...query,
    });
    return obs;
  }

  const columns: IColumn[] = [
    {
      dataIndex: 'poNumber',
      title: 'No PO',
      render: (val, record) => (
        <Link
          className="pl-2"
          to={`/features/po-management/details/${record?._id}`}
        >
          {val}
        </Link>
      ),
    },
    {
      dataIndex: 'userData',
      title: 'PIC PO',
      render: (val, record) => (
        <div className="row mt-1">
          <div className="col m-15">{val?.fullName}</div>
        </div>
      ),
      formatExprt: (val) => val.fullName,
    },
    {
      dataIndex: 'totalAmount',
      title: 'Plafon',
      render: (val, record) => (
        <div className="text-left">
          <div className="">{formatService.currency(val)}</div>
        </div>
      ),
    },
    {
      dataIndex: 'dateRange',
      ignoreDisply: false,
      title: 'Masa Berlaku',
      render: (val) => <div>{val}</div>,
      formatExprt: (val) => val,
    },
    {
      dataIndex: 'currentAmount',
      title: 'Sisa Saldo',
      ignoreDisply: false,
      render: (val) => <div className="">{formatService.currency(val)}</div>,
      formatExprt: (val) => val,
    },
    {
      dataIndex: 'shippingTotal',
      title: 'Jumlah Pengiriman',
      ignoreDisply: false,
      render: (val) => <div className="">{val}</div>,
      formatExprt: (val) => val,
    },
    {
      dataIndex: 'status',
      title: 'Status',
      ignoreDisply: false,
      render: (val) =>
        val ? (
          <div className="active status">Active</div>
        ) : (
          <div className="status expired">expired</div>
        ),
      formatExprt: (val) => (val ? 'active' : 'expired'),
    },
  ];

  const includes: IIncludes = {
    refresh: true,
    search: true,
    onlyExportAllData: true,
    fileNameExportAllData: 'List PO',
    importButton: {
      action: () => console.log('test'),
    },
    extraButtonsAfterRefresh: [
      {
        label: 'Tambah PO',
        iconName: 'i-Plus',
        type: 'primary',
        iconPosition: 'right',
        action: () => setShowModalCreate(true),
      },
    ],
  };

  const periodFilterType = [
    {
      label: 'Tanggal Mulai',
      value: 'Date',
    },
    {
      label: 'Tanggal Berakhir',
      value: 'Expired',
    },
  ];

  const sliceLocaleString = (e: string) => {
    return e.slice(0, 10);
  };

  const convertObjectToQueryUrl = (value: string): string => {
    return value
      .replace(/[{}]/g, '')
      .replace(/["]/g, '')
      .replace(',', '&')
      .replace(/[:]/g, '=');
  };

  const handleDateFilter = (e?: [Moment, Moment], type?: string) => {
    let startDate;
    let endDate;
    if (e !== undefined) {
      setDateFilterValue(e);
      if (e === null) {
        startDate = null;
        endDate = null;
      } else {
        startDate = sliceLocaleString(e[0].format('YYYY-MM-DD'));
        endDate = sliceLocaleString(e[1].format('YYYY-MM-DD'));
      }
    } else {
      startDate = sliceLocaleString(dateFilterValue[0].format('YYYY-MM-DD'));
      endDate = sliceLocaleString(dateFilterValue[1].format('YYYY-MM-DD'));
    }

    let queryString = {};
    if (!type) {
      queryString = {
        [`start${dateFilterType}`]: startDate,
        [`end${dateFilterType}`]: endDate,
      };
    } else {
      queryString = {
        [`start${type}`]: startDate,
        [`end${type}`]: endDate,
      };
    }

    setQuery(queryString);
    baseListRef.current.onSubmitDateFilter(query);
  };

  const onSelectDateFilterType = (e) => {
    setDateFilterType(e);
    handleDateFilter(undefined, e);
  };
  const PeriodFilterComp = () => {
    return (
      <div className="d-flex mr-auto">
        <Select
          defaultValue={dateFilterType}
          className="mr-2 h-fit extra-date-filter d-flex align-items-center"
          id="managePoListDateFilter"
          onChange={(e) => onSelectDateFilterType(e)}
          style={{
            width: '147px',
            alignItems: 'center',
          }}
        >
          {periodFilterType.map((el, i) => (
            <Option key={i} value={el.value}>
              {el.label}
            </Option>
          ))}
        </Select>
        <RangePicker
          className="date-filter-includes no-shadow h-36"
          style={{
            width: '216px',
          }}
          onChange={(e) => {
            console.log(e);
            handleDateFilter(e);
          }}
          value={dateFilterValue}
        />
      </div>
    );
  };

  const handleSubmitCreatePo = () => {
    setLoadingCreate(true);
    const validPeriod = {
      startDateValid: createPoForm
        .getFieldValue('validPeriod')[0]
        .format('YYYY-MM-DD'),
      endDateValid: createPoForm
        .getFieldValue('validPeriod')[1]
        .format('YYYY-MM-DD'),
    };

    createPoForm.setFieldsValue({
      ...validPeriod,
    });

    const obs = sampoernaManagePoRestService.createPreOrder(
      createPoForm.getFieldsValue(true)
    );
    handleService.handleRequest({
      obs,
      onError: () => setLoadingCreate(false),
      onDone: (res) => {
        setShowModalCreate(false);
        setLoadingCreate(false);
        notification.show({
          type: 'success',
          title: 'Berhasil',
          description: 'Berhasil Menambahkan PO',
        });
        baseListRef.current.callLoadData();
        createPoForm.resetFields();
      },
    });
  };

  return (
    <div>
      <BaseListComponent
        columns={columns}
        includes={includes}
        loadDataObservable={loadDataObservable}
        className="mt-4 po"
        ref={baseListRef}
        keyDataSource="data.list"
        keyPaginationTotal="data.total"
        keyPaginationTake="data.take"
        extraDateFilter={true}
        extraDateFilterComp={<PeriodFilterComp />}
      />
      <Modal
        visible={showModalCreate}
        width="80vw"
        style={{
          maxWidth: '380px',
        }}
        onCancel={() => {
          createPoForm.resetFields();
          setShowModalCreate(false);
        }}
        title="Tambah PO"
        onOk={() => createPoForm.submit()}
      >
        <Spinner spinning={loadingCreate}>
          <PoFormCreateComponent
            form={createPoForm}
            onFinish={handleSubmitCreatePo}
          />
        </Spinner>
      </Modal>
    </div>
  );
}
