import { LocalStorageService } from '@clodeo/libs/core/local-storage/local-storage.service';
import _ from 'lodash';

const localStorageService = new LocalStorageService();
export class MenuRfService {
  static get menus(): IMenuRf[] {
    const isOwner = localStorageService.getItem('currentUser')?.user?.isOwner;

    return _.compact([
      {
        id: 'order',
        icon: 'i-CirclesFour',
        label: 'ui.menu.order',
        permissions: ['manage-order'],
        to: '/features/order',
      },
      {
        id: 'report',
        icon: 'i-Note',
        label: 'ui.menu.report',
        permissions: ['manage-report'],
        to: '/features/report',
      },
      {
        id: 'contact',
        icon: 'i-PhoneBook',
        label: 'ui.menu.contact',
        permissions: ['manage-contact'],
        to: '/features/contact',
      },
      {
        id: 'user',
        icon: 'i-UserCircle',
        label: 'ui.menu.user',
        permissions: ['manage-user'],
        to: '/features/user',
      },
      {
        id: 'po-management',
        icon: 'i-NotePencil',
        label: 'ui.menu.ManagePO',
        permissions: ['manage-po'],
        to: '/features/po-management',
      },
    ]);
  }
}
