import React, { createContext, useEffect, useState } from 'react';
import PoDetailsComponent from '../../component/details/po-details-component/po-details.component';
import { AuthenticationService } from 'apps/sampoerna/src/app/core/auth/authentication.service';
import { environment } from 'apps/sampoerna/src/environments/environment';
import './po-details.scss';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { SampoernaPreOrderRestService } from '@clodeo/libs/core/rest/sampoerna/pre-order/sampoerna-pre-order.service';
import { useParams } from 'react-router-dom';
import { HandleService } from '@clodeo/clodeo-core/handle/handle.service';
import moment, { Moment } from 'moment';

interface IPODetails {
  type?: string;
}

export const PODetailContext = createContext(null);

export default function PoDetails(props: IPODetails) {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const formatService: FormatService = new FormatService();
  const sampoernaPreOrderRestService: SampoernaPreOrderRestService = new SampoernaPreOrderRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const { id }: any = useParams();
  const handleService = new HandleService();
  const [poData, setPoData] = useState<any>();
  const [filterType, setFilterType] = useState<string>('Date');
  const [dateFilterValue, setDateFilterValue] = useState<[Moment, Moment]>([moment().subtract(1, 'month'), moment(new Date())]);

  const loadPodata = () => {
    const query = `${id}`;
    const obs = sampoernaPreOrderRestService.getPreOrderDetail(query);
    handleService.handleRequest({
      obs,
      onDone: (res) => {
        if (res.success) {
          setPoData(res.data);
        }
      },
    });
  };

  function loadDataObservable(params) {
    if (filterType === 'Date') {
      params.startDate = dateFilterValue && dateFilterValue[0] ? moment(dateFilterValue[0]).format('YYYY-MM-DD') : null;
      params.endDate = dateFilterValue && dateFilterValue[1] ? moment(dateFilterValue[1]).format('YYYY-MM-DD') : null;
    } else {
      delete params.startDate;
      delete params.endDate;
    }

    const obs = sampoernaPreOrderRestService.getOrders({ poId: id, ...params });
    return obs;
  }

  const detailPoBoxStyle = {
    padding: '20px',
    borderRadius: '24px',
    background: 'white',
    // maxWidth: '1000px',
  };

  const DetailPoDataComponent = () => {
    return (
      <div style={detailPoBoxStyle}>
        <div className="row align-items-center">
          <div className="col-4">
            <div className="d-flex justify-content-between">
              <h5 className="text-14 mb-0 h-fit ">No. PO</h5>
              <div className="d-flex po-number-divider head-right align-items-center">
                <span className="mr-3 h-fit">:</span>
                <p className="mb-0 h-fit max-content">{poData?.poNumber}</p>
                <div
                  className={`status-box ${
                    poData?.status ? 'active' : 'expired'
                  }`}
                >
                  {poData?.status ? 'Active' : 'Expired'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-4">
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">PIC PO</p>
              <p className="head-right">
                <span className="mr-2">:</span>
                {poData?.pic_data?.fullName}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Email PIC PO</p>
              <p className="head-right">
                <span className="mr-2">:</span>
                {poData?.pic_data?.email}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Perusahaan</p>
              <p className="head-right d-flex">
                <span className="mr-2">:</span>
                <div>
                  {poData?.companyName || '-'}
                </div>
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Sisa Saldo</p>
              <p className=" head-right">
                <span className="mr-2">:</span>
                {formatService.currency(poData?.currentAmount)}
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Plafon</p>
              <p className=" head-right">
                <span className="mr-2">:</span>
                {formatService.currency(poData?.totalAmount)}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Digunakan</p>
              <p className=" head-right">
                <span className="mr-2">:</span>
                {formatService.currency(poData?.usedAmount)}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Pengiriman</p>
              <p className=" head-right">
                <span className="mr-2">:</span>
                {poData?.shippingTotal}
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Masa Berlaku</p>
              <p className=" head-right">
                <span className="mr-2">:</span>
                {poData?.dateRange}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Dibuat oleh</p>
              <p className="head-right">
                <span className="mr-2">:</span>
                {poData?.user_created?.fullName}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Email Finance Sicepat</p>
              <p className="head-right">
                <span className="mr-2">:</span>
                {poData?.user_created?.email}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Dibuat Pada</p>
              <p className="head-right">
                <span className="mr-2">:</span>
                {moment(poData?.createdAt?.slice(0, 10)).format('DD/MM/yyyy')}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-0 txt-head-gray">Attachment</p>
              <div className="head-right d-flex">
                <div className="mr-2">:</div>
                <div className='attachment'>
                  <a href={poData?.fileUrl?.url} style={{fontWeight:600}} download>{poData?.fileUrl?.originalFileName}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    loadPodata();
  }, []);

  return (
    <PODetailContext.Provider value={{ dateFilterValue, setDateFilterValue, filterType, setFilterType }}>
      <PoDetailsComponent
        type={props.type}
        head={<DetailPoDataComponent />}
        dataList={loadDataObservable}
      />
    </PODetailContext.Provider>
  );
}
