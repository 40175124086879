import { AutoComplete as BaseAutoComplete, Input } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import React from 'react';
const AutoComplete = (props: AutoCompleteProps) => (
  <BaseAutoComplete
    {...props}
    clearIcon={<ClearIcon />}
    showArrow
    suffixIcon={<Arrow />}
    allowClear
    className={'autocompete-deo ' + (props.className || ' ')}
    dropdownClassName={'drop-autocompete-deo ' + props.dropdownClassName}
  >
    {
      props.children
    }
  </BaseAutoComplete>
)

const ClearIcon = () => (
  <div className="remove-icon icon-deo-close">
  </div>
)

const Arrow = () => (
  <i className="icon-deo-arrow-down-1 icon-uhuy">
  </i>
)

const { Option } = BaseAutoComplete;

export { AutoComplete, Option };
