import React from 'react';
import { DatePicker as BaseDatePicker } from 'antd';
import { Moment } from 'moment';
import { PickerProps, RangePickerProps } from 'antd/lib/date-picker/generatePicker';

// for more custom style, check this file: styles\components\data-entry\_datepicker.scss
const DatePicker = (props: PickerProps<Moment>) => (
  <BaseDatePicker
    {...props}
    suffixIcon={props.suffixIcon || <LogoPicker />}
    className={"datepicker-deo " + (props.className || '')}
    dropdownClassName={"dropdownpicker-deo " + (props.dropdownClassName || '')}
    clearIcon={<ClearIcon />}
  />
)

const RangePicker = (props: RangePickerProps<Moment>) => (
  <BaseDatePicker.RangePicker
    {...props}
    separator="-"
    suffixIcon={props.suffixIcon || <LogoPicker />}
    className={"datepicker-deo " + (props.className || '')}
    dropdownClassName={"dropdownpicker-deo " + (props.dropdownClassName || '')}
    clearIcon={<ClearIcon />}
  />
)

const LogoPicker = () => (
  <div className="icon-custom">
    <img src="https://nx-design-system-web-development.clodeo.com/img/icon/component/calendar.svg" alt="" />
  </div>
)

const ClearIcon = () => (
  <div className="remove-icon icon-deo-close">
  </div>
)

export {
  DatePicker,
  RangePicker
}
