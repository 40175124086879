import React from 'react';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Form } from '@clodeo/clodeo-ui/components/form/antd-form.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { ICreatePoForm } from './po-create';
import SpvUserFieldComponent from '../field/spv/spv-user-field.component';
import AttachmentFieldComponent from '../field/attachment/attachment-field.component';
import { environment } from 'apps/sampoerna/src/environments/environment';
import { InputNumber } from '@clodeo/clodeo-ui/components/data-entry/inputnumber/inputnumber.component';
import './po-form-create.component.scss';
import CompanyFieldComponent from '../field/company/company-field.component';

export default function PoFormCreateComponent(props: ICreatePoForm) {
  const rules = [
    {
      required: true,
    },
  ];

  return (
    <div>
      <Form form={props.form} onFinish={props.onFinish}>
        <Form.Item
          name="whitelabelId"
          initialValue={environment.CONTENTS.WHITELABELID}
          className="d-none"
        />
        <div className="form-group">
          <label htmlFor="">
            No. PO<span className="required-field">*</span>
          </label>
          <Form.Item rules={rules} name="poNumber">
            <Input />
          </Form.Item>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="">
            PIC PO<span className="required-field">*</span>
          </label>
          <Form.Item rules={rules} name="spvName">
            <SpvUserFieldComponent
              form={props.form}
              target="spvUserId"
              className="w-100"
            />
          </Form.Item>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="">
            Perusahaan<span className="required-field">*</span>
          </label>
          <Form.Item rules={rules} name="companyName">
            <CompanyFieldComponent
              form={props.form}
              name="companyId"
            />
          </Form.Item>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="">
            Plafon<span className="required-field">*</span>
          </label>
          <Form.Item rules={rules} name="plafon" style={{ width: '100%' }}>
            <InputNumber
              width="100%"
              unit={{
                type: 'prepend',
                label: 'Rp',
              }}
            />
          </Form.Item>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="">
            Masa berlaku<span className="required-field">*</span>
          </label>
          <Form.Item rules={rules} name="validPeriod">
            <RangePicker style={{ width: '220px' }} />
          </Form.Item>
          <Form.Item name="startDateValid" className="d-none" />
          <Form.Item name="endDateValid" className="d-none" />
          <Form.Item name="spvUserId" className="d-none" />
        </div>
        <div className="form-group mt-2">
          <label htmlFor="">
            Attachment<span className="required-field">*</span>
          </label>
          <Form.Item name="attachmentId">
            <AttachmentFieldComponent
              placeholder="Contract PO.pdf"
              form={props.form}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
